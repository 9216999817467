import React from "react";
import { useSelector } from "react-redux";

import { TableCell, TableRow } from "@mui/material";

import DebounceTextField from "@components/Utility/DebounceTextField";
import { updatePurchaseOrder } from "@redux/slices/purchaseOrders/purchaseOrderSlice";
import { formatMoney } from "@utility/utilityFunctions";
import { moneyAdornment } from "@utils/forms";

const PurchaseOrderVariantTableFooter = ({
  totalRowsFirstCellLength,
  overview,
}: {
  totalRowsFirstCellLength: number;
  overview?: boolean;
}) => {
  const {
    currentPurchaseOrder: { status, id, totalFreight, totalTax, total, type },
    isLoading,
  } = useSelector((state: any) => state.purchaseOrder);

  const {
    organization: { includesTaxes },
  } = useSelector((state: any) => state.currentUser);

  return (
    <>
      {type !== "fulfillment" && (
        <>
          <TableRow>
            <TableCell
              colSpan={
                overview
                  ? totalRowsFirstCellLength + 1
                  : totalRowsFirstCellLength
              }
            >
              <b>Total Freight Cost:</b>
            </TableCell>
            {status !== "complete" && !overview && (
              <TableCell align="right" padding="checkbox">
                <DebounceTextField
                  value={formatMoney(totalFreight).split("$").join("")}
                  id={id}
                  setter={updatePurchaseOrder}
                  attr={"total-freight-cost"}
                  disabled={isLoading}
                  tw="m-0"
                  isNumberOnly
                  {...moneyAdornment}
                />
              </TableCell>
            )}
            <TableCell align="right">{formatMoney(totalFreight)}</TableCell>
            {status === "draft" && <TableCell />}
          </TableRow>
          {includesTaxes && (
            <TableRow>
              <TableCell colSpan={totalRowsFirstCellLength}>
                <b>Total Tax:</b>
              </TableCell>
              <TableCell align="right" colSpan={2}>
                {formatMoney(totalTax)}
              </TableCell>
              {status === "draft" && <TableCell />}
            </TableRow>
          )}
        </>
      )}
      <TableRow>
        <TableCell colSpan={totalRowsFirstCellLength}>
          <b>Grand Total:</b>
        </TableCell>
        <TableCell align="right" colSpan={2}>
          {formatMoney(total)}
        </TableCell>
        {status === "draft" && <TableCell />}
      </TableRow>
    </>
  );
};

export default PurchaseOrderVariantTableFooter;
