/** @jsxImportSource @emotion/react */
import "twin.macro";

import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";

import CloseIcon from "@mui/icons-material/CloseRounded";
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import clsx from "clsx";
import { sortBy } from "lodash";
import PropTypes from "prop-types";

import { addAddressIdToOrder } from "@redux/slices/orders/currentOrderSetSlice";
import client from "@services/api";
import { useApiResource } from "@services/api";
import { ControlledAutocompleteInput, ControlledTextInput } from "@utils/forms";

import { StyledButton } from "../../StyledComponents";
import SingleTerritoryAutoComplete from "../../Utility/AutoCompleteFields/SingleTerritoryAutoComplete";
import SingleUserAutoComplete from "../../Utility/AutoCompleteFields/SingleUserAutoComplete";

const StateSelector = ({ country, disabled, control }) => {
  const [currentStates, setCurrentStates] = useState([]);

  const { territoryList } = useSelector((state) => state.territories);
  const currentTerritoryId = useSelector(
    (state) => state.currentUser.currentTerritoryId
  );

  useEffect(() => {
    const states =
      country?.states?.length > 0
        ? country.states
        : territoryList.find((t) => t.id === currentTerritoryId)?.states;

    setCurrentStates(states ? sortBy(states, "code") : []);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [country]);

  return (
    <ControlledAutocompleteInput
      name="state"
      label="State"
      options={currentStates.map((s) => ({ id: s.id, name: s.code }))}
      disabled={disabled}
      control={control}
    />
  );
};

const useCheckUniqueName = (name, id) => {
  const { data, isLoading } = useApiResource(name && "addresses", {
    filter: { name },
    revalidate: false,
    revalidateIfStale: true,
  });
  return {
    nameTaken: data?.some((a) => a.id !== id && a.name === name),
    nameCheckLoading: isLoading,
  };
};

const useStyles = makeStyles((theme) => ({
  ...theme.global,
}));

const AddressModal = ({
  open,
  handleClose,
  type: _type,
  id,
  forOrder,
  orderSetId,
  orderType,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [type, setType] = useState(_type ?? "create");
  const [addressId, setAddressId] = useState(id ?? null);
  const [assignedUser, setAssignedUser] = useState(null);
  const [assignedTerritory, setAssignedTerritory] = useState(null);
  const [nameToCheck, setNameToCheck] = useState(null);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [country, setCountry] = useState("");
  const [state, setState] = useState("");
  const [modalError, setModalError] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [formLoading, setFormLoading] = useState(false);

  const {
    data: address,
    isValidating,
    update: updateAddress,
    error: errorAddress,
  } = useApiResource(addressId && "addresses", {
    id: addressId,
    revalidate: false,
    revalidateIfStale: true,
  });

  const {
    territories,
    currentTerritoryId,
    id: userId,
    role,
    organization: { addressBookType, countries: orgCountries },
  } = useSelector((state) => state.currentUser);

  const countryOptions = ["super", "admin"].includes(role)
    ? orgCountries
    : territories.find((t) => t.id === currentTerritoryId).countries;

  // TODO: When we do order SWR work
  // const { create: createOrder } = useApiResource("orders", {
  //   filter: { "order-set-id": orderSetId },
  // });

  const { nameTaken, nameCheckLoading } = useCheckUniqueName(
    nameToCheck,
    addressId
  );

  const {
    handleSubmit,
    reset: resetForm,
    control,
    watch,
    getValues,
  } = useForm({
    defaultValues: {
      name: "",
      country: "",
      state: "",
    },
  });
  const watchCountry = watch("country");

  const onSubmit = async (data) => {
    if (
      !country ||
      (country.states && country.states.length > 0 && !getValues("state"))
    ) {
      setModalError("Must choose country/state");
    } else {
      setFormLoading(true);
      setModalError(null);
      const formattedData = {
        ...data,
        type: "address",
        territoryId: assignedTerritory
          ? assignedTerritory.id
          : currentTerritoryId,
        relationshipNames: ["country", "state", "user"],
        country: {
          type: "country",
          id: data.country,
        },
        state: {
          type: "state",
          id: data.state,
        },
        user: {
          type: "user",
          id: assignedUser
            ? +assignedUser.id
            : addressBookType === "user"
            ? +userId
            : null,
        },
      };
      if (type === "edit") {
        await updateAddress({ id: address.id, ...formattedData });
        setSuccessMessage("Address updated successfully");
      } else {
        const cache = await client.post("addresses", formattedData);
        setAddressId(cache.data.id);
        setType("edit");
        setSuccessMessage("Address created successfully");

        if (forOrder) {
          dispatch(addAddressIdToOrder(orderSetId, cache.data.id, orderType));
          // TODO: The above line can be replaced with the stuff below when we do Order SWR overhaul
          // await createOrder({
          //   type: "order",
          //   orderSetId: orderSetId,
          //   addressId: cache.data.id,
          //   orderType: orderType,
          // });
        }
      }
      setFormSubmitted(true);
      setFormLoading(false);
    }
  };

  useEffect(() => {
    setCountry(countryOptions.find((c) => c.id === watchCountry));
  }, [watchCountry, countryOptions]);

  // Listen for address to update
  useEffect(() => {
    if (address) {
      resetForm({
        name: address.name ?? "",
        country: address.country.id ?? "",
        streetAddress1: address.streetAddress1 ?? "",
        streetAddress2: address.streetAddress2 ?? "",
        defaultAttn: address.defaultAttn ?? "",
        city: address.city ?? "",
        state: address.state?.id ?? "",
        region: address.region ?? "",
        zip: address.zip ?? "",
        phoneNumber: address.phoneNumber ?? "",
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [address]);

  useEffect(() => {
    if (address) {
      setState(address.state);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [address]);

  useEffect(() => {
    if (errorAddress) {
      setModalError(errorAddress);
    }
  }, [errorAddress, setModalError]);

  return (
    <div className={classes.relativeContainer}>
      <Dialog
        open={open}
        onClose={() => {
          setModalError(false);
          handleClose();
        }}
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle>
          <Typography className={classes.headerText}>
            {type === "edit" ? `Editing ${address?.name}` : "New Address"}
          </Typography>
        </DialogTitle>
        <DialogContent>
          <IconButton
            className={classes.closeButton}
            onClick={() => {
              setModalError(false);
              handleClose();
            }}
            size="large"
          >
            <CloseIcon />
          </IconButton>
          <div tw="p-4">
            <form>
              <Stack spacing={2}>
                <ControlledTextInput
                  label="Name / Reference Id"
                  name="name"
                  onBlur={(e) => setNameToCheck(e.target.value)}
                  error={nameTaken}
                  helperText={nameTaken ? "Address name already in use" : null}
                  control={control}
                  rules={{ required: true }}
                />
                <ControlledAutocompleteInput
                  name="country"
                  label="Country"
                  options={countryOptions}
                  disabled={address?.name.length === 0}
                  control={control}
                />
                <ControlledTextInput
                  label="Address Line One"
                  disabled={address?.name.length === 0}
                  name="streetAddress1"
                  control={control}
                  rules={{ required: true }}
                />
                <ControlledTextInput
                  label="Address Line Two"
                  disabled={address?.name.length === 0}
                  name="streetAddress2"
                  control={control}
                />
                <ControlledTextInput
                  label="Attention"
                  disabled={address?.name.length === 0}
                  name="defaultAttn"
                  control={control}
                />
                <ControlledTextInput
                  label="City"
                  disabled={address?.name.length === 0}
                  name="city"
                  control={control}
                  rules={{ required: true }}
                />
                {country?.states?.length > 0 &&
                  ((type === "edit" && state && typeof state === "object") ||
                    type === "new") && (
                    <StateSelector
                      country={country}
                      disabled={address?.name.length === 0}
                      control={control}
                    />
                  )}
                {(!country?.states || country?.states.length === 0) && (
                  <ControlledTextInput
                    label="Region"
                    disabled={address?.name.length === 0}
                    name="region"
                    control={control}
                  />
                )}
                <ControlledTextInput
                  label="Zip Code"
                  disabled={address?.name.length === 0}
                  name="zip"
                  control={control}
                  rules={{ required: true }}
                />
                <ControlledTextInput
                  label="Phone Number"
                  disabled={address?.name.length === 0}
                  name="phoneNumber"
                  control={control}
                  rules={{ required: true }}
                />
                {["super", "admin"].includes(role) && type === "new" && (
                  <>
                    <br />
                    <Divider className={classes.fullWidth} />
                    <br />
                    <Typography
                      className={clsx(
                        classes.headerText,
                        classes.settingsMargin
                      )}
                    >
                      {addressBookType === "user"
                        ? "User and Territory Assignment"
                        : "Territory Assignment"}
                    </Typography>
                    <Typography
                      className={classes.bodyText}
                      color="textSecondary"
                    >
                      {addressBookType === "user"
                        ? "* By default the address will be assigned you as a user, and your current territory.  If you would like to assign a different user or territory where the address will be visible, you can do so here"
                        : "* By default the address will be assigned your current territory, but if you would like to assign the territory that this address will be visible in, you can do so here"}
                    </Typography>
                    <br />
                    {addressBookType === "user" && (
                      <SingleUserAutoComplete
                        classes={classes}
                        handleChange={setAssignedUser}
                        disabled={address?.name.length === 0}
                        roles={null}
                        noMargin={false}
                      />
                    )}
                    <SingleTerritoryAutoComplete
                      classes={classes}
                      handleChange={setAssignedTerritory}
                      disabled={address?.name.length === 0}
                      noMargin={false}
                    />
                    <Button
                      className={classes.button}
                      variant="contained"
                      color="secondary"
                      onClick={() => {
                        setAssignedUser(null);
                        setAssignedTerritory(null);
                      }}
                      disabled={address?.name.length === 0}
                    >
                      RESET ASSIGNMENT
                    </Button>
                    <br />
                    <Divider className={classes.fullWidth} />
                    <br />
                  </>
                )}
                <br />
                {!isValidating && modalError && (
                  <Typography
                    className={clsx(classes.settingsMargin, classes.bodyText)}
                    style={{ color: "#920000" }}
                  >
                    {modalError}
                  </Typography>
                )}
                {formSubmitted && !isValidating && !errorAddress && (
                  <Typography className={classes.bodyText}>
                    {successMessage}
                  </Typography>
                )}
                {!isValidating && (
                  <StyledButton
                    cta
                    onClick={handleSubmit(onSubmit)}
                    loading={formLoading || isValidating || nameCheckLoading}
                  >
                    {type === "edit" ? "Update" : "Submit"}
                  </StyledButton>
                )}
                {formSubmitted && (
                  <StyledButton
                    style={{ marginLeft: "4px" }}
                    cta
                    onClick={handleClose}
                  >
                    CLOSE
                  </StyledButton>
                )}
                <br />
              </Stack>
            </form>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
};

AddressModal.propTypes = {
  id: PropTypes.string,
  type: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
};

export default React.memo(AddressModal);
