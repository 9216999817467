import usePreferExternalId from "src/hooks/usePreferExternalId";

import { OpaqueCard } from "@components/StyledComponents";
import SmartTable from "@components/Table/SmartTable";
import { variantName } from "@features/items";
import { GroupCategory, PurchaseOrderVariant } from "@models";
import { formatMoneyString } from "@utility/utilityFunctions";

const PurchaseOrderHistoryVariantTable = ({
  rows,
  groupCategories,
}: {
  rows: PurchaseOrderVariant[];
  groupCategories: GroupCategory[];
}) => {
  const preferExternalId = usePreferExternalId();

  return (
    <OpaqueCard>
      <SmartTable
        rows={rows}
        columns={[
          {
            id: "variant.variantSku",
            label: "SKU",
            render: (variantSku, pov) =>
              pov.isSetupFee
                ? "Setup Fee"
                : preferExternalId
                ? pov.purchaseOrder.warehouse
                : variantSku,
          },
          {
            id: "variant.item.name",
            label: "Item Name",
            render: (itemName, pov: PurchaseOrderVariant) =>
              !pov.isSetupFee ? itemName : "",
          },
          {
            id: "variant.item.description",
            label: "Item Description",
            render: (itemDescription, pov: PurchaseOrderVariant) =>
              !pov.isSetupFee ? itemDescription : pov.setupFeeDesc,
          },
          {
            id: "_variantNames",
            label: "Variant Names",
            render: (_, pov: PurchaseOrderVariant) =>
              !pov.isSetupFee ? variantName(pov.variant) : "",
          },
          ...groupCategories.map((gc, i) => ({
            id: `_groupCategory-${i}`,
            label: gc.name,
            render: (_, pov: PurchaseOrderVariant) =>
              !pov.isSetupFee
                ? pov.variant.item.groups.find(
                    (group) => group.groupCategory.id === gc.id
                  )?.name
                : "",
          })),
          {
            id: "qty",
            label: "Quantity",
          },
          {
            id: "actualCost",
            label: "Actual Cost",
            render: (actualCost) => formatMoneyString(actualCost),
          },
        ]}
        error={null}
      />
    </OpaqueCard>
  );
};

export default PurchaseOrderHistoryVariantTable;
