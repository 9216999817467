/** @jsxImportSource @emotion/react */
import "twin.macro";

import { useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";

import { ExitToApp, GetApp } from "@mui/icons-material";
import { CircularProgress, Tooltip, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";

import format from "date-fns/format";
import subDays from "date-fns/subDays";

import FilterPanel from "@components/Filtering/FilterPanel";
import ReportTable from "@components/Reporting/ReportTable";
import {
  FixedHeightScrollLastChild,
  OpaqueCard,
  StyledButton,
} from "@components/StyledComponents";
import { setGenerated } from "@redux/slices/reports/reportSlice";
import DocTitle from "@utility/DocTitle";
import { buildReportHeaders } from "@utility/buildReportHeaders";
import { csvDataFromSchema, downloadAsCsv } from "@utils/csv";

import { useChannel } from "../hooks/useChannel";
import { useInitialFetch } from "../hooks/useInitialFetch";
import { useReportCsv } from "../hooks/useReportCsv";
import useUrlQuery from "../hooks/useUrlQuery";
import FourOhFour from "./FourOhFour";

/*
The reports view takes in the report type as a url parameter, and based on the type, it uses buildReportHeaders,
useReportCSV, and useInitialFetch to fetch the correct report and dynamically build the table and csv export.
When adding a new report, make sure to add a description of that report in the report map, and add clauses for the
new report in useReportCsv, the report maps used in the reportSlice, and buildReportHeaders. You may also need to
add custom filters to be displayed in the FilterDrawer if there are no filters present in ../components/Reporting.
*/

const useStyles = makeStyles((theme) => ({
  ...theme.global,
  infoWrapper: {
    boxSizing: "border-box",
    padding: "16px",
    border: `2px solid ${theme.palette.secondary.light}`,
    width: "50%",
    textAlign: "center",
    margin: "20px auto 20px auto",
    [theme.breakpoints.down("lg")]: {
      width: "75%",
    },
    [theme.breakpoints.down("md")]: {
      width: "100%",
    },
  },
}));

const reportMap = {
  "order-window-summary": {
    title: "Order Window Summary",
    desc: "This report provides a summary of the total aggregated quantities for each item ordered on a Pre Order Window.",
  },
  "order-window-detail": {
    title: "Order Window Detail",
    desc: "This report gives  detailed order information for all items included in a Pre Order Window, including who ordered it and where the item is shipping to.",
  },
  "budget-adjustment-report": {
    title: "Budget Adjustment Report",
    desc: "The budget adjustment report gives a detailed legder of all adjustments and debits on the requested budget. A budget must be selected to generate the report.",
  },
  "budget-summary-export-report": {
    title: "Budget Summary Report",
    desc: "This report gives an overview of all budgets in their current state. The report can be filtered by its name, or status.",
  },
  "inventory-snapshot": {
    title: "Inventory Snapshot Report",
    desc: "This report gives a snapshot of the past year for ordered inventory items. Select a warehouse (or hit generate report if you only use one warehouse) to see order totals on items for the past 90 days, and past year.",
  },
  "inventory-velocity": {
    title: "Inventory Velocity Report",
    desc: "The Inventory Velocity Report gives a detailed account of the past year for each inventory item. You will be able to see how much has been ordered each month, total amounts ordered, and get insights on when to reorder and how much to order.",
  },
  "pre-order-user-report": {
    title: "Pre Order Non-Participants",
    desc: "Use this report to find out which users haven't ordered on a specific pre order window yet. Just select an order window in the filters by typing the name of the order window you'd like to find, and hit generate report.",
  },
  "promo-code-report": {
    title: "Promotion Summary Report",
    desc: "The Promotion Summary Report gives an overview of all promotions used within a given date range, or for specific promotions.",
  },
  "variant-allocation-report": {
    title: "Allocation Report",
    desc: "The Allocation Report gives a shapshot of current allocations, how much has been ordered against them, and their balances. The report can be filtered by sku or the allocations assignment.",
  },
  "shipped-orders-report": {
    title: "Shipped Orders Report",
    desc: "The Shipped Orders Report is a great tool for end of month reporting, and will give a detailed report on all items shipped withing a given date range. You can filter by the shipped date, or the ordered date as well as many other filters.",
  },
  "product-list-report": {
    title: "Item List Report",
    desc: "The Item List Report provides a detailed list of all currently available, or archived items, along with any available inventory they might have.",
  },
  "franchise-order-history-report": {
    title: "Franchise Order History",
    desc: "Franchise Order History",
  },
};

const defaultOrderHistoryFilters = {
  channelId: null,
  orderId: "",
  sku: "",
  orderType: null,
  groupOrderHistoryBy: "variant",
  dateRangeStart: format(subDays(new Date(), 30), "MM/dd/yyyy"),
  dateRangeEnd: format(new Date(), "MM/dd/yyyy"),
  groupIds: [],
  programIds: [],
  userIds: [],
  currentTerritoryId: null,
  ignoreUserTerritory: false,
  status: "submitted,approved,canceled",
  sortBy: null,
  sortDirection: null,
};

const Reports = () => {
  const params = useUrlQuery();
  const { type } = useParams();
  const dispatch = useDispatch();
  const classes = useStyles();

  const filters = useSelector((state) => state.filters);
  const { categories } = useSelector((state) => state.groupCategories);
  const { categories: variantCategories } = useSelector(
    (state) => state.variantCategories
  );
  const { currentChannelId, organization } = useSelector(
    (state) => state.currentUser
  );
  const { isLoading, reportType, reportData, hasGenerated } = useSelector(
    (state) => state.reports
  );

  const defaultFilters = {
    channelId: currentChannelId ?? null,
    ...(type === "budget-summary-export-report" && { budgetIsActive: true }),
    ...(type === "inventory-snapshot" && {
      warehouse: organization.warehouses.find(
        (warehouse) => warehouse !== "shipstation"
      ),
    }),
    ...(type === "inventory-velocity" && {
      ignoreUserChannel: true,
      groupedBy: "approval-date",
    }),
    ...((type === "shipped-orders-report" || type === "promo-code-report") && {
      dateRangeStart: format(subDays(new Date(), 30), "MM/dd/yyyy"),
      dateRangeEnd: format(new Date(), "MM/dd/yyyy"),
    }),
    ...(type === "shipped-orders-report" && {
      ignoreUserTerritory: true,
    }),
    ...(type === "franchise-order-history-report" &&
      defaultOrderHistoryFilters),
    ...(type === "pre-order-user-report" && {
      ignoreUserChannel: true,
    }),
    reportType: type,
    filterType: `reports-${type}`,
  };

  const { headers: schema, mapFunction } = buildReportHeaders({
    type,
    filters,
    categories,
    organization,
    variantCategories,
  });

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const mappedReportData = useMemo(() => mapFunction(reportData), [reportData]);

  const setHasGenerated = (value) => {
    dispatch(setGenerated({ value: value }));
  };

  const { data } = useReportCsv(
    type,
    hasGenerated,
    setHasGenerated,
    isLoading,
    mappedReportData,
    schema
  );

  useChannel();
  useInitialFetch(dispatch, `reports-${type}`, defaultFilters, params);

  if (!Object.keys(reportMap).includes(type)) {
    return <FourOhFour />;
  }

  return (
    <>
      <DocTitle title={`${reportMap[type].title}`} />
      <FixedHeightScrollLastChild>
        <header className={classes.titleBar}>
          <Typography className={classes.titleText}>
            {reportMap[type].title}
          </Typography>
          <div tw="flex items-center gap-4">
            <StyledButton
              outlined
              startIcon={<ExitToApp style={{ transform: "rotate(180deg)" }} />}
              component={Link}
              to="/reports-dashboard"
            >
              Back
            </StyledButton>
            {data.length > 0 && (
              <StyledButton
                cta
                startIcon={<GetApp />}
                onClick={() =>
                  downloadAsCsv(
                    csvDataFromSchema(schema, data),
                    `${type}-report.csv`
                  )
                }
              >
                EXPORT REPORT
              </StyledButton>
            )}
            {!data.length && (
              <Tooltip title="Must generate report first" enterDelay={300}>
                <div>
                  <StyledButton outlined startIcon={<GetApp />} disabled>
                    EXPORT REPORT
                  </StyledButton>
                </div>
              </Tooltip>
            )}
          </div>
        </header>
        <Typography className={classes.bodyText}>
          {reportMap[type].desc}
        </Typography>
        <FilterPanel />
        {(isLoading || type === reportType) && (
          <OpaqueCard tw="p-0 overflow-hidden">
            {isLoading && <CircularProgress tw="m-6" />}
            {!isLoading && (
              <ReportTable
                type={type}
                report={mappedReportData}
                headCells={schema}
              />
            )}
          </OpaqueCard>
        )}
      </FixedHeightScrollLastChild>
    </>
  );
};
export default Reports;
