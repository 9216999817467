import { buildQueryObject, separateByComma } from "@utility/utilityFunctions";

const handleGroups = (groups) => {
  let categoryGroups = groups.reduce((categoryGroup, group) => {
    let id = group.groupCategoryId;
    categoryGroup[id] = categoryGroup[id] || [];
    categoryGroup[id].push(group);
    return categoryGroup;
  }, {});
  let groupArray = Object.keys(categoryGroups).map((key) => ({
    group_category_id: key,
    group_ids: categoryGroups[key].map((group) => group.id),
  }));
  return `filter[groups]=${JSON.stringify(groupArray)}`;
};

const handleOrderId = (filters) => {
  if (filters.filterType?.includes("order-history")) {
    return filters.orderId && filters.orderId.length > 0
      ? filters.groupOrderHistoryBy === "order"
        ? `filter[id]=${filters.orderId}`
        : `filter[order-id]=${filters.orderId}`
      : null;
  } else {
    return null;
  }
};

const handleStatus = (filters) => {
  if (filters.filterType?.includes("order-history")) {
    return filters.status && filters.status.length > 0
      ? filters.groupOrderHistoryBy === "order"
        ? separateByComma("status", filters.status.split(","))
        : separateByComma("order-status", filters.status.split(","))
      : null;
  } else if (filters.filterType === "purchase-order-history") {
    return filters.status && filters.status.length > 0
      ? separateByComma("status", filters.status.split(","))
      : null;
  } else if (
    filters.filterType === "reports-order-window-summary" ||
    filters.filterType === "reports-order-window-detail"
  ) {
    return filters.status && filters.status.length > 0
      ? separateByComma("statuses", filters.status.split(","))
      : null;
  } else if (filters.filterType === "reports-product-list-report") {
    return filters.status && filters.status !== "all"
      ? `filter[status]=${filters.status}`
      : null;
  } else {
    return null;
  }
};

const handleSubmittedAtRange = (filters) => {
  // const draftStatuses = ["draft", "inactive", "in-progress", "missed-payment"];

  if (
    filters.filterType?.includes("order-history")
    // !draftStatuses.includes(filters.status)
  ) {
    return filters.dateRangeStart &&
      filters.dateRangeEnd &&
      filters.status !== "draft" &&
      filters.groupOrderHistoryBy
      ? filters.groupOrderHistoryBy === "order"
        ? `filter[submitted-at-range]=${filters.dateRangeStart} - ${filters.dateRangeEnd}`
        : `filter[order-submitted-at-range]=${filters.dateRangeStart} - ${filters.dateRangeEnd}`
      : null;
  } else {
    return null;
  }
};

export const buildFilters = (filters, uniqueFilter, sortString, urlBase) => {
  let budgetGroupIds =
    filters.budgetGroupIds && filters.budgetGroupIds.length > 0
      ? separateByComma("group-ids", filters.budgetGroupIds, "id")
      : null;
  let budgetId = filters.budget
    ? `filter[budget-id]=${filters.budget.id}`
    : null;
  let budgetIsActive =
    filters.filterType === "budgets" ||
    filters.filterType === "reports-budget-summary-export-report"
      ? filters.budgetIsActive
        ? `filter[is-active]=true`
        : `filter[is-active]=false`
      : null;
  let channelId =
    filters.channelId &&
    filters.channelId.length > 0 &&
    !filters.ignoreUserChannel
      ? separateByComma("channel-ids", filters.channelId)
      : null;
  let channelIds =
    filters.channelIds && filters.channelIds.length > 0
      ? separateByComma("channel-ids", filters.channelIds, "id")
      : null;
  let currentTerritoryId =
    filters.currentTerritoryId &&
    filters.currentTerritoryId.length > 0 &&
    !filters.ignoreUserTerritory
      ? separateByComma("territory-ids", filters.currentTerritoryId)
      : null;
  let customerIdentifier =
    filters.customerIdentifier && filters.customerIdentifier.length > 0
      ? `filter[customer-identifier]=${filters.customerIdentifier}`
      : null;
  let description =
    filters.description && filters.description.length > 0
      ? `filter[description]=${filters.description}`
      : null;
  let favoriteItems =
    filters.favoriteItems && filters.favoriteItems.length > 0
      ? separateByComma("ids", filters.favoriteItems, "id")
      : null;
  let groupIds =
    filters.groupIds && filters.groupIds.length > 0
      ? handleGroups(filters.groupIds)
      : null;
  let groupedBy =
    filters.groupedBy && filters.groupedBy.length > 0
      ? `filter[grouped-by]=${filters.groupedBy}`
      : null;
  let invoiceNumber =
    filters.invoiceNumber && filters.invoiceNumber.length > 0
      ? `filter[invoice-number]=${filters.invoiceNumber}`
      : null;
  let isActive =
    filters.isActive !== null && filters.isActive !== undefined
      ? `filter[is-active]=${filters.isActive}`
      : null;
  let isOrderable =
    filters.isOrderable !== null && filters.isOrderable !== undefined
      ? `filter[is-orderable]=${filters.isOrderable}`
      : null;
  let isVisible =
    filters.isVisible !== null && filters.isVisible !== undefined
      ? `filter[is-visible]=${filters.isVisible}`
      : null;
  let name =
    filters.name && filters.name.length > 0
      ? `filter[name]=${filters.name}`
      : null;
  let orderId = handleOrderId(filters);
  let orderHistoryType =
    filters.orderType &&
    ((filters.groupOrderHistoryBy &&
      filters.filterType?.includes("order-history")) ||
      filters.filterType === "reports-shipped-orders-report")
      ? filters.groupOrderHistoryBy === "order"
        ? `filter[type]=${filters.orderType}`
        : `filter[order-type]=${filters.orderType}`
      : null;
  let orderSubmittedRange = handleSubmittedAtRange(filters);
  let orderWindowId =
    filters.orderWindowId && filters.orderWindowId.length > 0
      ? `filter[order-window-id]=${filters.orderWindowId}`
      : null;
  let orderWindowIds =
    filters.filterType !== "reports-pre-order-user-report" &&
    filters.orderWindowIds &&
    filters.orderWindowIds.length > 0
      ? separateByComma("order-window-ids", filters.orderWindowIds, "id")
      : null;
  let orderWindowTerritoryId =
    filters.useOrderWindowTerritoryId &&
    filters.currentTerritoryId &&
    filters.currentTerritoryId.length > 0
      ? `filter[order-window-territory-id]=${filters.currentTerritoryId}`
      : null;
  let poId =
    filters.poId && filters.poId.length > 0
      ? `filter[id]=${filters.poId}`
      : null;
  let preOrderWindowId =
    filters.filterType === "reports-pre-order-user-report" &&
    filters.orderWindowIds &&
    filters.orderWindowIds.length > 0
      ? `order-window-id=${filters.orderWindowIds[0].id}`
      : null;
  let programId =
    filters.filterType === "reports-pre-order-user-report" && filters.programId
      ? `filter[program-id]=${filters.programId.id}`
      : null;
  let promotionIds =
    filters.promotionIds && filters.promotionIds.length > 0
      ? separateByComma("promotion-ids", filters.promotionIds, "id")
      : null;
  let promotionApprovedAtRange =
    filters.dateRangeStart &&
    filters.dateRangeEnd &&
    filters.filterType === "reports-promo-code-report"
      ? `filter[approved-at-range]=${filters.dateRangeStart} - ${filters.dateRangeEnd}`
      : null;
  let promotionRedemptionType =
    filters.filterType === "promotion" && filters.promotionRedemptionType
      ? `filter[redemption-type]=${filters.promotionRedemptionType}`
      : null;
  let promotionStatus =
    filters.filterType === "promotion" && filters.promotionStatus
      ? `filter[is_active]=${
          filters.promotionStatus === "active" ? "true" : "false"
        }`
      : null;
  let promotionType =
    filters.filterType === "promotion" && filters.promotionType
      ? `filter[type]=${filters.promotionType}`
      : null;
  let programIds =
    filters.programIds && filters.programIds.length > 0
      ? separateByComma("program-ids", filters.programIds, "id")
      : null;
  let purchaseOrderType =
    filters.filterType === "purchase-order-history" &&
    filters.orderType &&
    filters.orderType.length > 0
      ? `filter[type]=${filters.orderType}`
      : null;
  let purchaserIds =
    filters.purchaserIds && filters.purchaserIds.length > 0
      ? separateByComma("purchaser-ids", filters.purchaserIds, "id")
      : null;
  let roles =
    filters.roles && filters.roles.length > 0
      ? separateByComma("roles", filters.roles)
      : null;
  let rollupType =
    filters.orderType && filters.filterType === "purchase-order-rollup"
      ? `filter[order-set-type]=${filters.orderType}`
      : null;
  let shippedOrdersDateRange =
    filters.filterType === "reports-shipped-orders-report" &&
    filters.dateRangeStart &&
    filters.dateRangeEnd
      ? `filter[submitted-at-range]=${filters.dateRangeStart} - ${filters.dateRangeEnd}`
      : null;
  let sku =
    filters.sku && filters.sku.length > 0 ? `filter[sku]=${filters.sku}` : null;
  let stateIds =
    filters.stateIds && filters.stateIds.length > 0
      ? separateByComma("state-ids", filters.stateIds, "id")
      : null;
  let status = handleStatus(filters);
  let supplierIds =
    filters.supplierIds && filters.supplierIds.length > 0
      ? separateByComma("supplier-ids", filters.supplierIds, "id")
      : null;
  let supplierReference =
    filters.supplierReference && filters.supplierReference.length > 0
      ? `filter[supplier-reference]=${filters.supplierReference}`
      : null;
  let territoryIds =
    filters.territoryIds && filters.territoryIds.length > 0
      ? separateByComma("territory-ids", filters.territoryIds, "id")
      : null;
  let userIds =
    filters.userIds && filters.userIds.length > 0
      ? separateByComma("user-ids", filters.userIds, "id")
      : null;
  let warehouse =
    filters.warehouse && filters.warehouse.length > 0
      ? `filter[warehouse]=${filters.warehouse}`
      : null;
  let inStock = filters.inStock === "true" ? `filter[has-inventory]=true` : "";
  let itemVisibilityCaseString = filters.itemVisibilityCase
    ? `filter[${filters.itemVisibilityCase}]=true`
    : "";
  let dateRangeString = filters.dateRange
    ? `filter[date-range]=${filters.dateRange}`
    : "";

  let queryArray = [
    uniqueFilter,
    budgetGroupIds,
    budgetId,
    budgetIsActive,
    channelId,
    channelIds,
    currentTerritoryId,
    customerIdentifier,
    description,
    favoriteItems,
    groupIds,
    groupedBy,
    invoiceNumber,
    isActive,
    isOrderable,
    isVisible,
    name,
    orderHistoryType,
    orderId,
    orderSubmittedRange,
    orderWindowId,
    orderWindowIds,
    orderWindowTerritoryId,
    poId,
    preOrderWindowId,
    promotionIds,
    promotionApprovedAtRange,
    promotionRedemptionType,
    promotionStatus,
    promotionType,
    programId,
    programIds,
    purchaserIds,
    purchaseOrderType,
    roles,
    rollupType,
    shippedOrdersDateRange,
    sku,
    status,
    stateIds,
    supplierIds,
    supplierReference,
    territoryIds,
    userIds,
    warehouse,
    inStock,
    itemVisibilityCaseString,
    sortString,
    dateRangeString,
  ];

  let queryStringAppend = queryArray.filter((query) => query).join("&");
  let filterPreCursor = queryStringAppend.length !== 0 ? "?" : "";

  let queryString = urlBase + filterPreCursor + queryStringAppend;
  if (queryStringAppend.length > 0 && !filters.isAutoComplete) {
    let strippedFilters = buildQueryObject(filters);
    window.history.replaceState(
      null,
      "",
      `${window.location.pathname}?query=${JSON.stringify(strippedFilters)}`
    );
  } else if (!filters.isAutoComplete) {
    window.history.replaceState(null, "", window.location.pathname);
  }
  return queryString;
};

export const handleErrors = (err) => {
  if (err.response) {
    const errorPriority = [
      err.response.data?.error,
      err.response.data?.errors
        ?.map?.((e) => e.detail || e.title)
        ?.join?.("\n"),
      err.response.data.errors?.detail,
      err.response.errors?.detail,
      err.response.data?.message,
      err.response.data,
      "Unknown Error",
    ];
    //  return the first error that isn't falsey
    return errorPriority.reduce((a, b) => a || b, 0);
  } else {
    return "Unknown Error";
  }
};

export const buildSort = (filters) => {
  if (
    filters.sortBy &&
    filters.sortDirection &&
    filters.sortBy.length > 0 &&
    filters.sortDirection.length > 0
  ) {
    return `sort=${filters.sortDirection === "desc" ? "-" : ""}${
      filters.sortBy
    }`;
  } else return "";
};
