import { useRef, useState } from "react";
import { CSVReader } from "react-papaparse";
import { useDispatch, useSelector } from "react-redux";

import { GetAppRounded, PublishRounded } from "@mui/icons-material";
import {
  CircularProgress,
  Link,
  ListItemIcon,
  ListItemText,
  MenuItem,
  MenuList,
  Typography,
} from "@mui/material";

import { ReportSource, queueReport } from "@features/reports";
import { setError } from "@redux/slices/errorSlice";
import {
  updatePurchaseOrder,
  updateShippingParameterExtras,
} from "@redux/slices/purchaseOrders/purchaseOrderSlice";
import { useOpenCloudinaryWidget } from "@services/cloudinary";
import { mapShippingCSV } from "@utility/utilityFunctions";
import useRoleIs from "@utils/useRoleIs";

import { PurchaseOrderPdfLoader } from "../PDF/PDFLoader";

const PurchaseOrderOptions = () => {
  const dispatch = useDispatch();
  const csvRef = useRef(null);
  const openCloudinaryWidget = useOpenCloudinaryWidget();

  const { currentPurchaseOrder } = useSelector((state) => state.purchaseOrder);
  const roleIs = useRoleIs();

  const purchaseOrderId = currentPurchaseOrder.id;

  const canAccessShipReport = !(
    currentPurchaseOrder.requiresPurchasingAgent &&
    currentPurchaseOrder.type === "pre-order" &&
    roleIs("supplier")
  );

  const [isUploadLoading, setUploadLoading] = useState(false);

  const requestShippingReport = () => {
    dispatch(
      queueReport({
        reportName: `PO-${purchaseOrderId}-shipping-report`,
        resource: "reports/shipping-report",
        params: { filter: { purchaseOrderId } },
        source: ReportSource.CSV,
      })
    );
  };

  const uploadToCloudinary = () => {
    openCloudinaryWidget({
      onSuccess: (file) => {
        dispatch(
          updatePurchaseOrder(currentPurchaseOrder.id, {
            "additional-file-cloudinary-id": file.info.public_id,
            "additional-file-name": file.info.original_filename,
            "additional-file-resource-type": file.info.resource_type,
          })
        );
      },
    });
  };

  const handleFileUpload = (data) => {
    try {
      const mappedData = mapShippingCSV(data);
      if (mappedData.filter((data) => data.tax === "error").length > 0) {
        dispatch(
          setError({
            error: "The tax field must be a number or be left blank",
            source: "Shipping Information Upload",
          })
        );
      } else if (
        mappedData.filter((data) => data["actual-ship-date"] === "error")
          .length > 0
      ) {
        dispatch(
          setError({
            error:
              "Please ensure all dates are in the correct format (mm/dd/yyyy or mm-dd-yyyy or yyyy-mm-dd) and are the correct year",
            source: "Shipping Information Upload",
          })
        );
      } else {
        dispatch(
          updateShippingParameterExtras(mappedData, currentPurchaseOrder.id)
        );
      }
    } catch (e) {
      dispatch(setError({ error: e.message, source: "Image Upload" }));
      throw e;
    } finally {
      setUploadLoading(false);
    }
  };

  const handleFileUploadError = (err, file, inputElem, reason) => {
    dispatch(
      setError({ error: err.toString(), source: "Purchase Order File Upload" })
    );
    console.log(err, file, inputElem, reason);
  };

  const handleOpenDialog = (evt) => {
    if (csvRef.current) {
      csvRef.current.open(evt);
    }
  };

  return (
    <MenuList>
      <PurchaseOrderPdfLoader purchaseOrder={currentPurchaseOrder} />
      {!roleIs("supplier") && (
        <MenuItem onClick={uploadToCloudinary}>
          <ListItemIcon>
            <PublishRounded fontSize="small" />
          </ListItemIcon>

          <ListItemText>Upload Order Info</ListItemText>
        </MenuItem>
      )}
      {currentPurchaseOrder.additionalFileCloudinaryId &&
        currentPurchaseOrder.additionalFileResourceType && (
          <>
            <MenuItem
              component={Link}
              href={`https://res.cloudinary.com/brandhub/${currentPurchaseOrder.additionalFileResourceType}/upload/fl_attachment/${currentPurchaseOrder.additionalFileCloudinaryId}`}
            >
              <ListItemIcon>
                <GetAppRounded fontSize="small" />
              </ListItemIcon>
              <ListItemText>Download Order Info</ListItemText>
            </MenuItem>
            <MenuItem disabled>
              <Typography>
                {`File Name: ${currentPurchaseOrder.additionalFileName}`}
              </Typography>
            </MenuItem>
          </>
        )}
      {canAccessShipReport && (
        <>
          {!roleIs("purchasing-agent") && (
            <CSVReader
              ref={csvRef}
              onFileLoad={handleFileUpload}
              onError={handleFileUploadError}
              noClick
              noDrag
              config={{
                header: true,
                beforeFirstChunk: () => {
                  setUploadLoading(true);
                },
              }}
              noProgressBar
            >
              {({ file, error }) => (
                <>
                  <MenuItem
                    onClick={handleOpenDialog}
                    disabled={currentPurchaseOrder.status === "canceled"}
                  >
                    <ListItemIcon>
                      <PublishRounded fontSize="small" />
                    </ListItemIcon>
                    <ListItemText>Upload Shipping Info</ListItemText>
                    {isUploadLoading && <CircularProgress size={16} />}
                  </MenuItem>
                  {!isUploadLoading && (file || error) && (
                    <MenuItem disabled>
                      {file && !error && (
                        <Typography>
                          {`Successfully uploaded ${file?.name}`}
                        </Typography>
                      )}
                      {error && (
                        <Typography>{`Error uploading file: ${error}`}</Typography>
                      )}
                    </MenuItem>
                  )}
                </>
              )}
            </CSVReader>
          )}

          <MenuItem
            disabled={currentPurchaseOrder.status === "canceled"}
            onClick={requestShippingReport}
          >
            <ListItemIcon>
              <GetAppRounded fontSize="small" />
            </ListItemIcon>
            <ListItemText>Download Shipping Info</ListItemText>
          </MenuItem>
        </>
      )}
    </MenuList>
  );
};

export default PurchaseOrderOptions;
