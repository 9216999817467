/** @jsxImportSource @emotion/react */
import "twin.macro";

import React from "react";

import { CircularProgress } from "@mui/material";

import { useApiResource } from "@services/api";

const PurchasingAgentWarehouseAddress = () => {
  // At the moment, the only purchasing-agents warehouse is Vernon
  const { data: warehouses = [] } = useApiResource("addresses", {
    filter: {
      warehouse: "vernon",
    },
  });
  const w = warehouses[0];
  if (!w) return <CircularProgress />;
  return (
    <div tw="p-6 text-neutral-800">
      <div tw="text-lg">{w.name}</div>
      <div>{w.streetAddress1}</div>
      <div>{w.streetAddress2}</div>
      <div>
        {w.city}, {w.state.code} {w.zip}
      </div>
    </div>
  );
};

export default PurchasingAgentWarehouseAddress;
