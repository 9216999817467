import {
  axiosDelete,
  axiosGet,
  axiosPatch,
  axiosPost,
} from "src/api/axiosCalls";

import { createSlice } from "@reduxjs/toolkit";
import asyncPool from "@utility/asyncPool";
import { chunkArray, kebabCaseKeys } from "@utility/utilityFunctions";

import { setError } from "../errorSlice";
import {
  resetStepperValue,
  setIsStepper,
  updateStepperValue,
} from "../globalStateSlice";
import {
  setFailure as patchFailure,
  setIsLoading as patchLoading,
  patchSuccess,
} from "../patchOrderSlice";
import { updateSupplierCounts } from "../user/currentUserSlice";
import {
  buildPurchaseOrder,
  buildPurchaseOrderPatch,
  buildPurchaseOrderVariantPatch,
  buildSetupFee,
  buildShippingParameterExtras,
  buildShippingParameterPatch,
} from "./helpers";
import {
  mapPurchaseOrder,
  mapPurchaseOrderVariants,
  mapShippingParameters,
} from "./maps";

let initialState = {
  isLoading: false,
  isUpdateLoading: false,
  currentPurchaseOrder: {
    id: null,
    orgId: null,
    additionalFileCloudinaryId: null,
    additionalFileName: null,
    additionalFileResourceType: null,
    inMarketDate: null,
    invoiceNumber: null,
    isDirectShip: null,
    method: null,
    note: null,
    labelingInstructions: null,
    terms: null,
    changeNote: null,
    status: null,
    submittedAt: null,
    warehouse: null,
    totalFreight: null,
    totalTax: null,
    total: null,
    supplierReference: null,
    warehouseTrackingNumber: null,
    purchaser: null,
    purchaserId: null,
    supplier: null,
    supplierId: null,
    type: null,
    purchaseOrderVariants: [],
    shippingParameters: [],
  },
  additionalRollupItems: [],
  hasAdditionalRollupItems: false,
  redirect: null,
  hasUpdated: false,
  error: null,
};

const purchaseOrderSlice = createSlice({
  name: "purchaseOrder",
  initialState,
  reducers: {
    setIsLoading(state) {
      state.isLoading = true;
    },
    setIsUpdateLoading(state) {
      state.isUpdateLoading = true;
    },
    getPurchaseOrderSuccess(state, action) {
      const { purchaseOrder, type } = action.payload;
      state.currentPurchaseOrder = purchaseOrder;
      if (type === "new") {
        state.hasUpdated = true;
      }
      if (type !== "update") {
        state.isLoading = false;
      }
      if (type === "update") {
        state.isUpdateLoading = false;
      }
      state.error = null;
    },
    deletePurchaseOrderSuccess(state) {
      state.hasUpdated = true;
      state.currentPurchaseOrder = initialState.currentPurchaseOrder;
      state.isLoading = false;
    },
    createSetupFeeSuccess(state, action) {
      const { setupFee } = action.payload;
      state.currentPurchaseOrder.purchaseOrderVariants =
        state.currentPurchaseOrder.purchaseOrderVariants.concat(setupFee);
      state.currentPurchaseOrder.total =
        state.currentPurchaseOrder.total + setupFee.actualCost;
      state.isUpdateLoading = false;
      state.error = null;
    },
    updatePurchaseOrderVariantSuccess(state, action) {
      const { purchaseOrderVariant } = action.payload;
      const currentVariant =
        state.currentPurchaseOrder.purchaseOrderVariants.find(
          (pov) => pov.id === purchaseOrderVariant.id
        );
      state.currentPurchaseOrder.purchaseOrderVariants =
        state.currentPurchaseOrder.purchaseOrderVariants.map((pov) => {
          if (pov.id === purchaseOrderVariant.id) return purchaseOrderVariant;
          else return pov;
        });
      state.currentPurchaseOrder.total =
        state.currentPurchaseOrder.total -
        currentVariant.actualCost * currentVariant.qty +
        purchaseOrderVariant.actualCost * purchaseOrderVariant.qty;
      state.isUpdateLoading = false;
      state.error = null;
    },
    deletePurchaseOrderVariantSuccess(state, action) {
      const { id } = action.payload;
      const currentVariant =
        state.currentPurchaseOrder.purchaseOrderVariants.find(
          (pov) => pov.id === id
        );
      state.currentPurchaseOrder.purchaseOrderVariants =
        state.currentPurchaseOrder.purchaseOrderVariants.filter(
          (pov) => pov.id !== id
        );
      state.currentPurchaseOrder.total =
        state.currentPurchaseOrder.total -
        currentVariant.actualCost * currentVariant.qty;
      state.isUpdateLoading = false;
      state.error = null;
    },
    updateShippingParameterSuccess(state, action) {
      const { shippingParameter } = action.payload;
      state.currentPurchaseOrder.shippingParameters =
        state.currentPurchaseOrder.shippingParameters.map((sp) => {
          if (sp.id === shippingParameter.id) return shippingParameter;
          else return sp;
        });
      state.isUpdateLoading = false;
      state.error = null;
    },
    getAdditionalRollupItemsSuccess(state, action) {
      const { items } = action.payload;
      state.additionalRollupItems = items;
      state.hasAdditionalRollupItems = true;
      state.isLoading = false;
    },
    resetCurrentPurchaseOrder(state) {
      state.currentPurchaseOrder = { ...initialState.currentPurchaseOrder };
    },
    setRedirect(state, action) {
      const { redirect } = action.payload;
      state.redirect = redirect;
    },
    setHasUpdated(state, action) {
      const { value } = action.payload;
      state.hasUpdated = value;
    },
    resetHasAdditionalRollupItems(state) {
      state.hasAdditionalRollupItems = false;
      state.additionalRollupItems = [];
    },
    resetError(state) {
      state.error = null;
    },
    setFailure(state, action) {
      const { error } = action.payload;
      state.isLoading = false;
      state.isUpdateLoading = false;
      state.error = error;
    },
  },
});

export const {
  setIsLoading,
  setIsUpdateLoading,
  getPurchaseOrderSuccess,
  deletePurchaseOrderSuccess,
  createSetupFeeSuccess,
  updatePurchaseOrderVariantSuccess,
  deletePurchaseOrderVariantSuccess,
  updateShippingParameterSuccess,
  getAdditionalRollupItemsSuccess,
  resetCurrentPurchaseOrder,
  setRedirect,
  setHasUpdated,
  resetHasAdditionalRollupItems,
  resetError,
  setFailure,
} = purchaseOrderSlice.actions;

export default purchaseOrderSlice.reducer;

export const fetchPurchaseOrder = (id) => async (dispatch) => {
  try {
    dispatch(setIsLoading());
    const response = await axiosGet(`/api/purchase-orders/${id}`);
    if (response.error) {
      throw response.error;
    }
    const mappedData = mapPurchaseOrder(response.data);
    dispatch(
      getPurchaseOrderSuccess({ purchaseOrder: mappedData, type: "initial" })
    );
  } catch (err) {
    dispatch(setFailure({ error: err.toString() }));
    dispatch(setError({ error: err.toString(), source: "Purchase Orders " }));
  }
};

export const createPurchaseOrder = (attrs, callback) => async (dispatch) => {
  try {
    dispatch(setIsLoading());
    const postData = buildPurchaseOrder(attrs);
    const response = await axiosPost("/api/purchase-orders", postData);
    if (response.error) {
      throw response.error;
    }
    const mappedData = mapPurchaseOrder(response.data);
    dispatch(
      getPurchaseOrderSuccess({ purchaseOrder: mappedData, type: "new" })
    );
    callback?.(mappedData);
  } catch (err) {
    dispatch(setFailure({ error: err.toString() }));
    dispatch(setError({ error: err.toString(), source: "Purchase Orders" }));
  }
};

export const createFulfillmentPurchaseOrder =
  (attrs, callback) => async (dispatch) => {
    try {
      dispatch(setIsLoading());
      const postData = buildPurchaseOrder(attrs);
      const response = await axiosPost(
        "/api/convert-to-fulfillment-purchase-order",
        postData
      );
      if (response.error) {
        throw response.error;
      }
      const mappedData = mapPurchaseOrder(response.data);
      dispatch(
        getPurchaseOrderSuccess({ purchaseOrder: mappedData, type: "new" })
      );
      callback?.(mappedData);
    } catch (err) {
      dispatch(setFailure({ error: err.toString() }));
      dispatch(setError({ error: err.toString(), source: "Purchase Orders" }));
    }
  };

export const createSetupFee = (attrs) => async (dispatch) => {
  try {
    dispatch(patchLoading());
    dispatch(setIsUpdateLoading());
    const postData = buildSetupFee(attrs);
    const response = await axiosPost("/api/purchase-order-variants", postData);
    if (response.error) {
      throw response.error;
    }
    const mappedData = mapPurchaseOrderVariants([response.data])[0];
    dispatch(createSetupFeeSuccess({ setupFee: mappedData }));
    dispatch(patchSuccess());
  } catch (err) {
    dispatch(setFailure({ error: err.toString() }));
    dispatch(patchFailure());
    dispatch(setError({ error: err.toString(), source: "Purchase Orders" }));
  }
};

export const updatePurchaseOrder = (id, attrs) => async (dispatch) => {
  try {
    dispatch(patchLoading());
    dispatch(setIsUpdateLoading());
    const patchData = buildPurchaseOrderPatch(id, attrs);
    const response = await axiosPatch(`/api/purchase-orders/${id}`, patchData);
    if (response.error) {
      throw response.error;
    }
    const mappedData = mapPurchaseOrder(response.data);
    dispatch(
      getPurchaseOrderSuccess({ purchaseOrder: mappedData, type: "update" })
    );
    dispatch(patchSuccess());
  } catch (err) {
    dispatch(setFailure({ error: err.toString() }));
    dispatch(patchFailure());
    dispatch(setError({ error: err.toString(), source: "Purchase Orders" }));
  }
};

export const updatePurchaseOrderStatus =
  (id, action, body = {}) =>
  async (dispatch, getState) => {
    const { role } = getState().currentUser;
    try {
      dispatch(patchLoading());
      dispatch(setIsUpdateLoading());
      const response = await axiosPost(
        `/api/purchase-orders/${id}/${action}`,
        body
      );
      if (response.error) throw response.error;
      if (action === "decline") {
        dispatch(
          setRedirect({ redirect: "/purchasing/purchase-order-history" })
        );
        if (role === "supplier") {
          dispatch(updateSupplierCounts({ newCount: -1, inProgressCount: 0 }));
        }
      }
      if (action === "accept" && role === "supplier") {
        dispatch(updateSupplierCounts({ newCount: -1, inProgressCount: 1 }));
      }
      if (action === "complete" && role === "supplier") {
        dispatch(updateSupplierCounts({ newCount: 0, inProgressCount: -1 }));
      }
      const mappedData = mapPurchaseOrder(response.data);
      dispatch(
        getPurchaseOrderSuccess({ purchaseOrder: mappedData, type: "update" })
      );
      dispatch(patchSuccess());
    } catch (err) {
      dispatch(setFailure({ error: err.toString() }));
      dispatch(patchFailure());
      dispatch(setError({ error: err.toString(), source: "Purchase Orders" }));
    }
  };

export const sendChangeNotification = (id) => async (dispatch) => {
  try {
    dispatch(patchLoading());
    dispatch(setIsUpdateLoading());
    const response = await axiosPost(`/api/purchase-orders/${id}/notify`);
    if (response.error) throw response.error;
    const mappedData = mapPurchaseOrder(response.data);
    dispatch(
      getPurchaseOrderSuccess({ purchaseOrder: mappedData, type: "update" })
    );
    dispatch(patchSuccess());
  } catch (err) {
    dispatch(setFailure({ error: err.toString() }));
    dispatch(patchFailure());
    dispatch(setError({ error: err.toString(), source: "Purchase Orders" }));
  }
};

export const deletePurchaseOrder = (id) => async (dispatch) => {
  try {
    dispatch(setIsLoading());
    const response = await axiosDelete(`/api/purchase-orders/${id}`, {});
    if (response.error) {
      throw response.error;
    }
    dispatch(deletePurchaseOrderSuccess());
  } catch (err) {
    dispatch(setFailure({ error: err.toString() }));
    dispatch(setError({ error: err.toString(), source: "Purchase Orders" }));
  }
};

export const createPurchaseOrderVariant =
  ({ variantQtys, warehouse }, callback) =>
  async (dispatch, getState) => {
    try {
      const { id: purchaseOrderId } =
        getState().purchaseOrder.currentPurchaseOrder;
      dispatch(patchLoading());
      dispatch(setIsUpdateLoading());
      async function createVariant({ id, qty }) {
        return axiosPost("/api/purchase-order-variants", {
          data: {
            attributes: kebabCaseKeys({
              isToInventory: true,
              purchaseOrderId,
              warehouse,
              variantId: id,
              qty,
            }),
          },
        }).then((res) => {
          if (res.error) throw new Error(res.error);
        });
      }
      const [firstQty, ...rest] = variantQtys;
      // according to Shaun, we need to create the first variant synchronously, the rest can be done in parallel
      // otherwise it creates race conditions in the back-end
      await createVariant(firstQty);
      const { errors } = await asyncPool(5, rest, createVariant);

      if (errors) {
        throw new Error(errors[0]);
      }

      const res = await axiosGet(`/api/purchase-orders/${purchaseOrderId}`);
      if (res.error) {
        throw new Error(res.error);
      }
      const mappedData = mapPurchaseOrder(res.data);
      dispatch(
        getPurchaseOrderSuccess({ purchaseOrder: mappedData, type: "update" })
      );
      dispatch(patchSuccess());
      callback?.();
    } catch (err) {
      dispatch(setFailure({ error: err.toString() }));
      dispatch(patchFailure());
      dispatch(setError({ error: err.toString(), source: "Purchase Orders" }));
    }
  };

export const updatePurchaseOrderVariant = (id, attrs) => async (dispatch) => {
  try {
    dispatch(patchLoading());
    dispatch(setIsUpdateLoading());
    const patchData = buildPurchaseOrderVariantPatch(id, attrs);
    const response = await axiosPatch(
      `/api/purchase-order-variants/${id}`,
      patchData
    );
    if (response.error) {
      throw response.error;
    }
    const mappedData = mapPurchaseOrderVariants([response.data])[0];
    dispatch(
      updatePurchaseOrderVariantSuccess({ purchaseOrderVariant: mappedData })
    );
    dispatch(patchSuccess());
  } catch (err) {
    dispatch(setFailure({ error: err.toString() }));
    dispatch(patchFailure());
    dispatch(setError({ error: err.toString(), source: "Purchase Orders" }));
  }
};

export const deletePurchaseOrderVariant = (id) => async (dispatch) => {
  try {
    dispatch(patchLoading());
    dispatch(setIsUpdateLoading());
    const response = await axiosDelete(
      `/api/purchase-order-variants/${id}`,
      {}
    );
    if (response.error) {
      throw response.error;
    }
    dispatch(deletePurchaseOrderVariantSuccess({ id: id }));
    dispatch(patchSuccess());
  } catch (err) {
    dispatch(setFailure({ error: err.toString() }));
    dispatch(patchFailure());
    dispatch(setError({ error: err.toString(), source: "Purchase Orders" }));
  }
};

export const updateShippingParameter = (id, attrs) => async (dispatch) => {
  try {
    dispatch(patchLoading());
    dispatch(setIsUpdateLoading());
    const patchData = buildShippingParameterPatch(id, attrs);
    const response = await axiosPatch(
      `/api/shipping-parameters/${id}`,
      patchData
    );
    if (response.error) {
      throw response.error;
    }
    const mappedData = mapShippingParameters([response.data])[0];
    dispatch(updateShippingParameterSuccess({ shippingParameter: mappedData }));
    dispatch(patchSuccess());
  } catch (err) {
    dispatch(setFailure({ error: err.toString() }));
    dispatch(patchFailure());
    dispatch(setError({ error: err.toString(), source: "Purchase Orders" }));
  }
};

export const updateShippingParameterVariant =
  (id, poId, type, attrs) => async (dispatch) => {
    try {
      dispatch(patchLoading());
      dispatch(setIsUpdateLoading());
      const response = await axiosPost(
        `/api/shipping-parameter-variants/${id}/${type}`,
        attrs
      );
      if (response.error) {
        throw response.error;
      }
      const poResponse = await axiosGet(`/api/purchase-orders/${poId}`);
      if (poResponse.error) {
        throw poResponse.error;
      }
      const mappedData = mapPurchaseOrder(poResponse.data);
      dispatch(
        getPurchaseOrderSuccess({ purchaseOrder: mappedData, type: "update" })
      );
      dispatch(patchSuccess());
    } catch (err) {
      dispatch(setFailure({ error: err.toString() }));
      dispatch(patchFailure());
      dispatch(setError({ error: err.toString(), source: "Purchase Orders" }));
    }
  };

export const updateShippingParameterExtras =
  (spvArray, id) => async (dispatch) => {
    try {
      dispatch(
        setIsStepper({
          stepBool: true,
          stepTitle: "Uploading Shipping Document",
        })
      );

      const validSpvArray = spvArray.filter(
        (spv) => spv.id && spv.id.length > 0
      );
      const chunkedArray = chunkArray(validSpvArray, 5);
      let count = validSpvArray.length <= 5 ? 1 : chunkedArray.length;
      let response;
      let stepValue;
      let patchData;

      if (count === 1) {
        patchData = buildShippingParameterExtras(chunkedArray);
        response = await axiosPatch(
          "/api/shipping-parameter-variants/add-shipping-extras",
          patchData
        );
        if (response.error) {
          throw response.error;
        }
      } else {
        stepValue = parseFloat((100 / (count + 1)).toFixed(2));
        for (let i = 0; i < count; i++) {
          patchData = buildShippingParameterExtras(chunkedArray[i]);
          response = await axiosPatch(
            "/api/shipping-parameter-variants/add-shipping-extras",
            patchData
          );
          if (response.error) {
            throw response.error;
          }
          dispatch(updateStepperValue({ value: stepValue }));
        }
      }
      if (id) {
        const poResponse = await axiosGet(`/api/purchase-orders/${id}`);
        if (poResponse.error) {
          throw poResponse.error;
        }
        const mappedData = mapPurchaseOrder(poResponse.data);
        dispatch(
          getPurchaseOrderSuccess({ purchaseOrder: mappedData, type: "update" })
        );
      }
      dispatch(updateStepperValue({ value: count === 1 ? 50 : stepValue }));
      dispatch(resetStepperValue());
    } catch (err) {
      dispatch(setFailure({ error: err.toString() }));
      dispatch(resetStepperValue());
      dispatch(setError({ error: err.toString(), source: "Purchase Orders" }));
    }
  };
