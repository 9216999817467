import { addMonths, differenceInMonths, format } from "date-fns";

import SkuInfoIcon from "@components/Utility/SkuInfoIcon";

import { orderHistoryByOrder, orderHistoryByVariant } from "./inViewReporting";

/*
Each report will have it's own headers for the report table, and the csv export based on the report
type that is passed into the function. The categories passed in are based on the organizaitons group
categories and the groups within those, and the api will always send an array of the groups that
have the category id, so they can be mapped to the correct header in the tables and csv exports.
*/

export const buildReportHeaders = ({
  type,
  filters,
  categories,
  organization,
  variantCategories,
}) => {
  let categoryHeaders = categories.map((c) => ({
    id: `group-${c.id}`,
    label: c.name,
  }));

  const variantOptionHeaders = variantCategories.map((c) => ({
    id: `variant-option-${c.id}`,
    label: c.name,
  }));

  let headers = [];
  let mapFunction = (obj) => obj;

  switch (type) {
    case "order-window-summary":
      headers = [
        { id: "name", label: "Name" },
        { id: "sku", label: "Sku" },
        {
          id: "customerIdentifier",
          label: "Product Identifier",
        },
        { id: "externalWarehouseId", label: "External Warehouse Id" },
        { id: "supplier", label: "Supplier" },
        { id: "orderWindowName", label: "Order Window" },
        { id: "program", label: "Program" },
        ...categoryHeaders,
        { id: "qty", label: "Total Qty Ordered" },
        { id: "moq", label: "MOQ" },
        { id: "cost", label: "Cost / Unit" },
        { id: "totalCost", label: "Extended Cost" },
        { id: "price", label: "Price / Unit" },
        { id: "totalPrice", label: "Extended Price" },
        {
          id: "discount",
          label: "Total Promotional Discount",
        },
      ];
      break;
    case "order-window-detail":
      headers = [
        { id: "orderId", label: "Order #" },
        { id: "orderDate", label: "Order Date" },
        { id: "orderWindowName", label: "Order Window" },
        { id: "program", label: "Program" },
        // key account
        { id: "supplier", label: "Supplier" },
        { id: "accountCode", label: "Account Code" },
        { id: "orderer", label: "Ordered By" },
        { id: "orderedByEmail", label: "Ordered By Email" },
        { id: "territory", label: "Territory" },
        { id: "addressAttn", label: "Ship-To Attn" },
        {
          id: "addressStreetOne",
          label: "Street One",
        },
        {
          id: "addressStreetTwo",
          label: "Street Two",
        },
        { id: "addressCity", label: "City" },
        { id: "addressState", label: "State" },
        { id: "addressZip", label: "Zip" },
        { id: "addressCountry", label: "Country" },
        { id: "sku", label: "SKU" },
        {
          id: "customerIdentifier",
          label: "Product Identifier",
        },
        { id: "externalWarehouseId", label: "External Warehouse Id" },
        { id: "name", label: "Name" },
        ...variantOptionHeaders,
        { id: "packSize", label: "Packout" },
        ...categoryHeaders,
        { id: "qty", label: "Total Qty" },
        { id: "promotions", label: "Promotion Code" },
        { id: "discount", label: "Promotion Amount" },
        { id: "cost", label: "Cost / Unit" },
        { id: "totalCost", label: "Extended Cost" },
        { id: "price", label: "Price / Unit" },
        { id: "totalPrice", label: "Extended Price" },
        { id: "totalEstimatedShippingCost", label: "Total Estimated Freight" },
        { id: "totalEstimatedTax", label: "Total Estimated Tax" },
        { id: "paymentType", label: "Payment Type" },
        { id: "budgetName", label: "Budget" },
        { id: "status", label: "Order Status" },
      ];
      break;
    case "budget-adjustment-report":
      headers = [
        { id: "date", label: "Date" },
        { id: "user", label: "Name" },
        { id: "email", label: "Email" },
        { id: "note", label: "Note" },
        {
          id: "originalBalance",
          label: "Original Balance",
        },
        { id: "adjustment", label: "Adjustment Value" },
        { id: "total", label: "New Balance" },
      ];
      break;
    case "budget-summary-export-report":
      headers = [
        { id: "id", label: "Id" },
        { id: "name", label: "Budget" },
        { id: "assignedTo", label: "Assigned To" },
        { id: "startDate", label: "Start Date" },
        {
          id: "expirationDate",
          label: "Expiration Date",
        },
        { id: "totalAmount", label: "Total Amount" },
        { id: "spend", label: "Spend" },
        { id: "balance", label: "Balance" },
        { id: "status", label: "Status" },
      ];
      break;
    case "inventory-snapshot":
      headers = [
        { id: "sku", label: "Sku" },
        {
          id: "customerIdentifier",
          label: "Product Identifier",
        },
        { id: "warehouseSKU", label: "Warehouse SKU" },
        { id: "name", label: "Product Name" },
        ...categoryHeaders,
        { id: "totalOnHand", label: "Total on Hand" },
        { id: "cost", label: "Cost / Unit" },
        { id: "inventoryValue", label: "Inventory Value" },
        { id: "ninetyDayQty", label: "90 Day Order Qty" },
        { id: "yearQty", label: "Year Order Qty" },
      ];
      break;
    case "variant-allocation-report":
      headers = [
        { id: "sku", label: "Sku" },
        {
          id: "customerIdentifier",
          label: "Product Identifier",
        },
        { id: "itemName", label: "Item Name" },
        { id: "allocatedTo", label: "Allocated To" },
        {
          id: "totalAllocated",
          label: "Total Allocated",
        },
        { id: "ordered", label: "Ordered Qty" },
        { id: "pending", label: "Pending Qty" },
        { id: "available", label: "Available Qty" },
        {
          id: "unallocatedOrderQty",
          label: "Unallocated Ordered Qty",
        },
      ];
      break;
    case "inventory-velocity":
      let monthArray = [];
      // Build the month array from the dateRange filter
      if (filters.dateRange) {
        const [start, end] = filters.dateRange.split(" - ");
        const startDate = new Date(start);
        const endDate = new Date(end);
        let monthCount = differenceInMonths(endDate, startDate);

        for (let i = 0; i <= monthCount; i++) {
          const currentDate = addMonths(startDate, i);
          const dateKey = format(currentDate, "MM-yyyy");
          const label = format(currentDate, "MMMM-yyyy");
          monthArray.push({
            id: dateKey,
            label: label,
          });
        }
      }

      headers = [
        {
          id: "sku",
          label: "Sku",
          Component: (
            <>
              Sku <SkuInfoIcon sku />
            </>
          ),
        },
        {
          id: "customerIdentifier",
          label: "Product Identifier",
          Component: (
            <>
              Product Identifier <SkuInfoIcon />
            </>
          ),
        },
        { id: "warehouseSKU", label: "Warehouse SKU" },
        { id: "name", label: "Product Name" },
        ...categoryHeaders,
        { id: "cost", label: "Cost / Unit" },
        ...monthArray,
        {
          id: "totalQty",
          label: "Total Units Ordered",
        },
        { id: "monthAverage", label: "Month Average" },
        { id: "leadTime", label: "Lead Time (Days)" },
        { id: "reorderQty", label: "Qty on Reorder" },
        {
          id: "reorderThreshold",
          label: "Reorder Threshold",
        },
        { id: "onHandQty", label: "Total On Hand" },
        { id: "packSize", label: "Pack Size" },
        {
          id: "inventoryValue",
          label: "Inventory Value",
        },
        {
          id: "weeksOfInventory",
          label: "Weeks of Inventory",
        },
      ];
      break;
    case "pre-order-user-report":
      headers = [
        { id: "name", label: "Name" },
        { id: "email", label: "Email" },
        { id: "orderWindow", label: "Order Window" },
        { id: "program", label: "Program" },
        { id: "territory", label: "Territory" },
      ];
      break;
    case "promo-code-report":
      headers = [
        { id: "promotion", label: "Promotion" },
        { id: "orderSetCount", label: "Use Count" },
        { id: "orderCount", label: "Order Count" },
        { id: "discounts", label: "Discounts" },
        { id: "totalSales", label: "Total Sales" },
        {
          id: "totalSalesAfterDiscount",
          label: "Total Sales After Discount",
        },
      ];
      break;
    case "shipped-orders-report":
      headers = [
        {
          id: "orderId",
          label: "Order Number",
        },
        {
          id: "sku",
          label: "Sku",
          Component: (
            <>
              Sku <SkuInfoIcon sku />
            </>
          ),
        },
        {
          id: "customerIdentifier",
          label: "Product Identifier",
          Component: (
            <>
              Product Identifier <SkuInfoIcon />
            </>
          ),
        },
        { id: "warehouseSKU", label: "Warehouse SKU" },
        { id: "itemName", label: "Item Name" },
        ...categoryHeaders,
        { id: "accountCode", label: "Account Code" },
        { id: "orderedBy", label: "Ordered By" },
        { id: "orderedByEmail", label: "Ordered By Email" },
        { id: "orderType", label: "Order Type" },
        { id: "orderedDate", label: "Ordered Date" },
        { id: "expeditedDate", label: "Expedited Date" },
        { id: "approvedDate", label: "Approved Date" },
        { id: "shippedDate", label: "Shipped Date" },
        { id: "territory", label: "Territory" },
        { id: "isForEvent", label: "For Event" },
        { id: "addressName", label: "Address" },
        {
          id: "addressStreetOne",
          label: "Street One",
        },
        {
          id: "addressStreetTwo",
          label: "Street Two",
        },
        { id: "addressCity", label: "City" },
        { id: "addressState", label: "State / Region" },
        { id: "addressZip", label: "Zip" },
        { id: "addressCountry", label: "Country" },
        { id: "qty", label: "Qty" },
        { id: "price", label: "Price / Unit" },
        { id: "totalPrice", label: "Extended Price" },
        {
          id: "estShippingCost",
          label: "Est. Shipping",
        },
        { id: "estimatedTax", label: "Est. Tax" },
        ...(organization.allowShippingCostsAtOrderLevel
          ? [
              { id: "actualFreight", label: "Actual Shipping" },
              { id: "actualTax", label: "Actual Tax" },
            ]
          : []),
        { id: "checkoutType", label: "Payment Type" },
        { id: "carrier", label: "Carrier" },
        { id: "tracking", label: "Tracking" },
        { id: "budgetName", label: "Budget" },
      ];
      break;
    case "product-list-report":
      headers = [
        {
          id: "sku",
          label: "Sku",
          Component: (
            <>
              Sku <SkuInfoIcon sku />
            </>
          ),
        },
        {
          id: "customerIdentifier",
          label: "Product Identifier",
          Component: (
            <>
              Product Identifier <SkuInfoIcon />
            </>
          ),
        },
        {
          id: "externalWarehouseId",
          label: "External Warehouse Id",
          Component: (
            <>
              External Warehouse Id <SkuInfoIcon />
            </>
          ),
        },
        { id: "name", label: "Name" },
        { id: "description", label: "Description" },
        ...categoryHeaders,
        { id: "supplier", label: "Supplier" },
        { id: "visibility", label: "Visibility" },
        { id: "unitOfMeasure", label: "Unit of Measure" },
        { id: "packSize", label: "Pack Size" },
        { id: "cost", label: "Cost" },
        { id: "price", label: "Price" },
        { id: "orderType", label: "Order Type" },
        { id: "onHand", label: "On Hand Inventory" },
        {
          id: "reorderThreshold",
          label: "Reorder Threshold",
        },
        { id: "status", label: "Status" },
        {
          id: "orderableStartDate",
          label: "Orderable Start Date",
        },
        {
          id: "orderableEndDate",
          label: "Orderable End Date",
        },
        {
          id: "visibleStartDate",
          label: "Visible Start Date",
        },
        {
          id: "visibleEndDate",
          label: "Visible End Date",
        },
      ];

      mapFunction = (obj) =>
        obj.map((o) => ({
          ...o,
          description: o.description?.replace(/\r?\n/g, " "),
        }));
      break;

    case "franchise-order-history-report":
      if (filters.groupOrderHistoryBy === "order") {
        const res = orderHistoryByOrder(organization);
        headers = [
          ...res.headers,
          { id: "userExternalId", label: "Supplier Owner ID" },
          { id: "addressExternalId", label: "Supplied Address ID" },
        ];
        mapFunction = res.mapFunction;
      } else {
        const res = orderHistoryByVariant(categories, organization);
        headers = [
          ...res.headers,
          { id: "userExternalId", label: "Supplier Owner ID" },
          { id: "addressExternalId", label: "Supplied Address ID" },
        ];
        mapFunction = res.mapFunction;
      }
      break;
    default:
      break;
  }

  return { headers, mapFunction };
};
