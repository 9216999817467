import { useSelector } from "react-redux";

import { PinDropRounded } from "@mui/icons-material";
import { AccountTreeRounded } from "@mui/icons-material";
import AdminPanelSettingsRoundedIcon from "@mui/icons-material/AdminPanelSettingsRounded";
import BarChartRoundedIcon from "@mui/icons-material/BarChartRounded";
import CategoryRoundedIcon from "@mui/icons-material/CategoryRounded";
import DashboardRoundedIcon from "@mui/icons-material/DashboardRounded";
import InventoryRoundedIcon from "@mui/icons-material/InventoryRounded";
import LocalShippingRoundedIcon from "@mui/icons-material/LocalShippingRounded";
import PieChartRoundedIcon from "@mui/icons-material/PieChartRounded";
import RequestQuoteRoundedIcon from "@mui/icons-material/RequestQuoteRounded";

import { UserRole } from "@models/UserRole";
import permissions from "@utils/permissions";

type MenuItem = {
  label: string;
  icon: any;
  to: string;
  permissions?: UserRole[];
  children?: MenuItem[];
};

// Recursively filter for permission
const filterPermissions = (arr, role) =>
  arr.reduce((menuArr, item) => {
    if (!item.permissions || item.permissions.includes(role)) {
      if (item.children) {
        item.children = filterPermissions(item.children, role);
      }
      if (!item.children || item.children.length > 0) {
        menuArr.push(item);
      }
    }
    return menuArr;
  }, []);

const useUserMenu = () => {
  const {
    currentUser,
    orderWindows: { availableWindows },
  } = useSelector((state: any) => state);
  const {
    budgetLocation,
    usesInventory,
    usesCostCenters,
    usesApprovalFlow,
    usesOnDemand,
    usesChannels,
    usesRfqs,
    hasBeacons,
    restrictItemArchiveToAdmins,
  } = currentUser.organization;

  const orderWindowNav = availableWindows.map((w) => ({
    to: `/pre-orders/${w.id}`,
    label: w.name,
  }));

  const menu: MenuItem[] = [
    {
      label: "Dashboard",
      icon: DashboardRoundedIcon,
      to: "/",
      exactMatch: true,
    },
    {
      label: "Admin",
      icon: AdminPanelSettingsRoundedIcon,
      permissions: [...permissions.admin, "approver" as const],
      children: [
        {
          label: "Site Settings",
          to: "/admin/settings",
          permissions: permissions.super,
        },
        {
          label: "Addresses",
          to: "/admin/addresses",
          permissions: permissions.admin,
        },
        Boolean(budgetLocation) && {
          label: "Budgets",
          to: "/admin/budgets",
        },
        usesChannels && {
          label: "Channels",
          to: "/admin/channels",
          permissions: permissions.admin,
        },
        usesCostCenters && {
          label: "Cost Centers",
          to: "/admin/cost-centers",
        },
        {
          label: "Dashboards",
          to: "/admin/dashboards",
          permissions: permissions.admin,
        },

        {
          label: "Item Settings",
          to: "/admin/item-settings",
          permissions: permissions.admin,
        },

        {
          label: "Suppliers",
          to: "/admin/suppliers",
          permissions: permissions.super,
        },
        {
          label: "Territories",
          to: "/admin/territories",
          permissions: permissions.admin,
        },
        { label: "Users", to: "/admin/users", permissions: permissions.admin },
      ].filter(Boolean),
    },
    {
      label: "Program Admin",
      icon: AccountTreeRounded,
      permissions: permissions.admin,
      children: [
        { label: "Items", to: "/admin/items", permissions: permissions.admin },
        {
          label: "Order Windows",
          to: "/admin/order-windows",
        },
        {
          label: "Programs",
          to: "/admin/programs",
        },
        {
          label: "Promotions",
          to: "/admin/promotions",
        },
        usesRfqs && {
          label: "Quote Requests",
          to: "/rfqs",
        },
      ].filter(Boolean),
    },
    usesRfqs && {
      label: "Quotes",
      icon: RequestQuoteRoundedIcon,
      permissions: permissions.supplier,
      children: [
        {
          label: "Quote Requests",
          to: "/quotes",
        },
      ],
    },
    {
      label: "Ordering",
      icon: LocalShippingRoundedIcon,
      permissions: permissions.orders,
      children: [
        ...orderWindowNav,
        usesInventory && {
          label: "New Inventory Order",
          to: "/orders/draft/inventory",
        },
        usesOnDemand && {
          label: "New On-demand Order",
          to: "/orders/draft/on-demand",
        },
        {
          label: "Order History",
          to: "/orders/history",
        },

        !!budgetLocation && {
          label: "Budgets",
          to: "/budgets",
        },
        {
          label: "Programs",
          to: "/programs",
        },
        usesApprovalFlow && {
          label: "Order Approval",
          to: "/orders/approval",
          permissions: permissions.orderReview,
        },
      ].filter(Boolean),
    },
    {
      label: "Purchasing",
      icon: InventoryRoundedIcon,
      permissions: permissions.purchaseOrders,
      children: [
        {
          label: "New Purchase Order",
          to: "/purchasing/purchase-order-rollup",
          permissions: permissions.purchaseOrderRollup,
        },
        {
          label: "Purchase Order History",
          to: `/purchasing/purchase-order-history`,
        },
      ],
    },
    {
      label: "Items",
      icon: CategoryRoundedIcon,
      permissions: permissions.items,
      children: [
        {
          label: "Current Items",
          to: "/items",
          exactMatch: true,
        },
        {
          label: "Archived",
          to: "/items/archive",
          ...(restrictItemArchiveToAdmins && {
            permissions: permissions.admin,
          }),
        },
      ],
    },
    {
      label: "Analytics",
      icon: PieChartRoundedIcon,
      permissions: permissions.reporting,
      children: [
        {
          label: "Order Activity",
          to: "/analytics/orderactivity",
        },
        {
          label: "Pre Order Early Warning",
          to: "/analytics/preorderearlywarning",
        },
        {
          label: "Inventory Snapshot",
          to: "/analytics/inventorysnapshot",
        },
      ],
    },
    {
      label: "Reports",
      icon: BarChartRoundedIcon,
      permissions: permissions.reporting,
      children: [
        {
          label: "All Reports",
          to: "/reports-dashboard",
        },
        {
          label: "Order Window Summary",
          to: "/reports/order-window-summary",
        },
        {
          label: "Order Window Detail",
          to: "/reports/order-window-detail",
        },
        // {
        //   label: "Shipped Orders Report",
        //   to: "/reports/shipped-orders-report",
        // },
        // {
        //   label: "Pre Order Non-Participants",
        //   to: "/reports/pre-order-user-report",
        // },
        // {
        //   label: "Promotion Summary Report",
        //   to: "/reports/promo-code-report",
        // },
        // {
        //   label: "Item List Report",
        //   to: "/reports/product-list-report",
        // },
        // ...((Boolean(budgetLocation) && [
        //   {
        //     label: "Budget Adjustment Report",
        //     to: "/reports/budget-adjustment-report",
        //   },
        //   {
        //     label: "Budget Summary Export",
        //     to: "/reports/budget-summary-export-report",
        //   },
        // ]) ||
        //   []),

        // ...((usesInventory && [
        //   {
        //     label: "Inventory Snapshot Report",
        //     to: "/reports/inventory-snapshot",
        //   },
        //   {
        //     label: "Inventory Velocity Report",
        //     to: "/reports/inventory-velocity",
        //   },
        //   {
        //     label: "Inventory Check Report",
        //     to: "/reports/inventory-check",
        //   },
        // ]) ||
        //   []),
      ],
    },
    hasBeacons && {
      label: "Beacons",
      icon: PinDropRounded,
      permissions: permissions.beacons,
      to: "/beacons",
    },
  ].filter(Boolean);

  return filterPermissions(menu, currentUser.role);
};

export default useUserMenu;
