import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";

import { CancelTwoTone, ExitToApp } from "@mui/icons-material";
import {
  Button,
  Container,
  Divider,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import clsx from "clsx";

import AllocationModal from "@components/BrandHQ/Items/AllocationModal";
import Loading from "@components/Utility/Loading";
import SuccessModal from "@components/Utility/Modals/SuccessModal";
import StandardTableHead from "@components/Utility/StandardTableHead";
import {
  deleteVariantAllocation,
  fetchItem,
  resetAllocationHistory,
  setHasUpdated,
} from "@redux/slices/items/itemSlice";
import DocTitle from "@utility/DocTitle";

import { useDetailedInput } from "../hooks/inputs/useDetailedInput";
import { useNoFetch } from "../hooks/useNoFetch";

/*
This view handles viewing, creating, and updating variant allocations. These work similarly to Shelf
Inventory in RTA, but in Brandhub, allocations can be set to either territories or users.
*/

const useStyles = makeStyles((theme) => ({
  ...theme.global,
  twoWideContainer: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    [theme.breakpoints.down("md")]: {
      flexDirection: "column",
    },
  },
  twoWide: {
    width: "48%",
    [theme.breakpoints.down("md")]: {
      width: "100%",
    },
  },
  formWrapper: {
    width: "70%",
    maxWidth: "1250px",
    padding: "20px",
    boxSizing: "border-box",
    textAlign: "center",
    [theme.breakpoints.down("lg")]: {
      width: "85%",
    },
    [theme.breakpoints.down("md")]: {
      width: "100%",
    },
  },
}));

const VariantAllocations = () => {
  const { id } = useParams();
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [currentItem, setCurrentItem] = useState(null);
  const [currentVariant, setCurrentVariant] = useState(null);
  const [currentAllocations, setCurrentAllocations] = useState([]);
  const [hasDeleted, setHasDeleted] = useState(false);
  const [successModal, setSuccessModal] = useState({
    open: false,
    message: null,
    options: [],
  });
  const [allocationModal, setAllocationModal] = useState({
    open: false,
    allocation: null,
    variantId: null,
    sku: null,
    itemId: null,
    type: null,
    available: null,
    packSize: null,
  });

  const {
    isLoading,
    isUpdateLoading,
    hasUpdated,
    currentItem: item,
    error,
  } = useSelector((state) => state.items);
  const {
    organization: { allocationLocation },
  } = useSelector((state) => state.currentUser);

  let headCells =
    allocationLocation === "user"
      ? [{ id: "user", label: "User" }]
      : [{ id: "territory", label: "Territory" }];

  headCells = headCells.concat([
    { id: "allocatedQty", label: "Allocated Qty" },
    { id: "availableQty", label: "Available Qty" },
    { id: "delete", label: "Delete" },
  ]);

  const handleSearch = (value, _type, _filter) => {
    if (value.length !== 0) {
      setCurrentAllocations(
        [...currentVariant.allocations].filter((a) => {
          if (allocationLocation === "user") {
            return a.user.name.toLowerCase().includes(value);
          } else return a.territory.name.toLowerCase().includes(value);
        })
      );
    } else setCurrentAllocations([...currentVariant.allocations]);
  };

  const {
    value: search,
    bind: bindSearch,
    reset: resetSearch,
  } = useDetailedInput("", handleSearch);

  const handleSetCurrentVariant = (id) => {
    const newSelectedVariant = currentItem.variants.find((v) => v.id === id);
    setCurrentVariant(newSelectedVariant);
    setCurrentAllocations(newSelectedVariant.allocations);
    resetSearch();
  };

  const handleSuccessClose = (arg) => {
    if (arg === "continue") {
      dispatch(setHasUpdated({ value: false }));
    } else {
      navigate("/admin/items");
    }
  };

  const handleAllocationModalClose = () => {
    setAllocationModal({
      open: false,
      allocation: null,
      variantId: null,
      sku: null,
      itemId: null,
      type: null,
      available: null,
      packSize: null,
    });
    dispatch(resetAllocationHistory);
  };

  const handleDelete = (id) => {
    dispatch(deleteVariantAllocation(id, currentItem.id));
    setHasDeleted(true);
  };

  useEffect(() => {
    if (hasUpdated && !successModal.open) {
      setSuccessModal({
        open: true,
        message: hasDeleted
          ? "Allocation Deleted Successfully"
          : "Allocation Set Successfully!",
        options: [
          { label: "CONTINUE EDITING", arg: "continue" },
          { label: "BACK TO ITEMS", arg: "back" },
        ],
      });
    } else if (!hasUpdated && successModal.open) {
      setSuccessModal({ open: false, message: null, options: [] });
    }
    if (hasDeleted) setHasDeleted(false);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasUpdated, successModal.open]);

  useEffect(() => {
    if (
      !isLoading &&
      !isUpdateLoading &&
      (hasUpdated || !currentItem || currentItem.id !== id)
    ) {
      if (item) {
        const updatedVariant =
          item.variants.find((v) => v.id === currentVariant?.id) ||
          item.variants[0];
        setCurrentItem(item);
        setCurrentVariant(updatedVariant);
        setCurrentAllocations(updatedVariant.allocations);
      }
    }
  }, [
    currentItem,
    hasUpdated,
    id,
    isLoading,
    isUpdateLoading,
    item,
    currentVariant?.id,
  ]);

  useEffect(() => {
    if (error && hasDeleted) setHasDeleted(false);
  }, [error, hasDeleted]);
  useNoFetch(dispatch);

  useEffect(() => {
    dispatch(fetchItem(id));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <DocTitle title={"Allocations"} />
      {successModal.open && (
        <SuccessModal {...successModal} handleClose={handleSuccessClose} />
      )}
      {allocationModal.open && (
        <AllocationModal
          {...allocationModal}
          handleClose={handleAllocationModalClose}
        />
      )}
      {(!currentItem ||
        currentItem.id !== id ||
        isLoading ||
        isUpdateLoading) && <Loading opacity="75%" />}
      {currentItem &&
        currentItem.id === id &&
        !isLoading &&
        !isUpdateLoading && (
          <Container
            className={clsx(classes.center, classes.mainWrapper)}
            style={{ flexDirection: "column" }}
          >
            <div className={classes.formWrapper}>
              <header className={classes.titleBar}>
                <Typography className={classes.headerText}>
                  {`Setting Inventory Allocations for ${currentItem.sku}`}
                </Typography>
                <div className={classes.innerConfigDiv}>
                  <Button
                    className={classes.button}
                    variant="contained"
                    color="secondary"
                    onClick={() =>
                      setAllocationModal({
                        open: true,
                        allocation: null,
                        variantId: currentVariant.id,
                        sku: currentVariant.sku,
                        itemId: id,
                        type: "new",
                        available: currentVariant.qty,
                        packSize: item.packSize,
                      })
                    }
                  >
                    NEW ALLOCATION
                  </Button>
                  <Button
                    className={classes.button}
                    variant="contained"
                    color="secondary"
                    startIcon={
                      <ExitToApp style={{ transform: "rotate(180deg)" }} />
                    }
                    component={Link}
                    to={"/admin/items"}
                  >
                    BACK TO ITEMS
                  </Button>
                </div>
              </header>
              <br />
              <Typography className={classes.bodyText} color="textSecondary">
                <em>
                  {`Clicking NEW ALLOCATION will create a new allocation for Sku: ${currentVariant.sku}`}
                </em>
              </Typography>
              <br />
              <Typography className={classes.bodyText}>
                <b>Name:</b>
                {` ${item.name}`}
              </Typography>
              <Typography className={classes.bodyText}>
                <b>Description:</b>
                {` ${item.description}`}
              </Typography>
              <br />
              <Typography className={classes.bodyText}>
                <b>Current On Hand Inventory:</b>
                {` ${currentVariant.warehouseQty}`}
              </Typography>
              <Typography className={classes.bodyText}>
                <b>Current Available Allocated Inventory:</b>
                {` ${
                  currentVariant.allocations.length > 0
                    ? currentVariant.allocations.reduce(
                        (a, b) => a + b.availableQty,
                        0
                      )
                    : 0
                }`}
              </Typography>
              <Typography className={classes.bodyText}>
                <b>Current Available Unallocated Inventory:</b>
                {` ${currentVariant.qty}`}
              </Typography>
              <br />
              <div className={classes.twoWideContainer}>
                <FormControl
                  size="small"
                  variant="outlined"
                  className={clsx(classes.twoWide, classes.settingsMargin)}
                >
                  <InputLabel id="order-type-label">Sku</InputLabel>
                  <Select
                    label="Sku"
                    labelid="order-type-label"
                    id="order-type-select"
                    value={currentVariant.id}
                    onChange={(evt) =>
                      handleSetCurrentVariant(evt.target.value)
                    }
                  >
                    {currentItem.variants.map((v) => (
                      <MenuItem value={v.id} key={v.sku}>
                        {v.sku}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <TextField
                  disabled={currentVariant.allocations.length === 0}
                  size="small"
                  className={classes.twoWide}
                  variant="outlined"
                  color="secondary"
                  name="search"
                  type="text"
                  label={`${
                    allocationLocation === "user"
                      ? "Search User Allocations"
                      : "Search Territory Allocations"
                  }`}
                  value={search}
                  {...bindSearch}
                />
              </div>
              <br />
              {currentAllocations.length === 0 && (
                <>
                  <Divider className={classes.fullWidth} />
                  <br />
                  <Typography className={classes.headerText}>
                    There are currently no allocations for the selected Sku.
                  </Typography>
                </>
              )}
              {currentAllocations.length > 0 && (
                <TableContainer>
                  <Table stickyHeader className={classes.table}>
                    <StandardTableHead
                      classes={classes}
                      headCells={headCells}
                    />
                    <TableBody>
                      {currentAllocations.map((a) => (
                        <TableRow
                          key={a.id}
                          hover
                          className={classes.clickableRow}
                          onClick={() =>
                            setAllocationModal({
                              open: true,
                              allocation: { ...a },
                              variantId: currentVariant.id,
                              sku: currentVariant.sku,
                              itemId: id,
                              type: "edit",
                              available: currentVariant.qty,
                              packSize: item.packSize,
                            })
                          }
                        >
                          <TableCell align="left">
                            {allocationLocation === "user"
                              ? a.user.name
                              : a.territory.name}
                          </TableCell>
                          <TableCell align="left">{a.allocatedQty}</TableCell>
                          <TableCell align="left">{a.availableQty}</TableCell>
                          <TableCell align="center" padding="checkbox">
                            <IconButton
                              onClick={(evt) => {
                                evt.stopPropagation();
                                handleDelete(a.id);
                              }}
                              size="large"
                            >
                              <CancelTwoTone color={"secondary"} />
                            </IconButton>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              )}
            </div>
          </Container>
        )}
    </>
  );
};

VariantAllocations.propTypes = {};

export default VariantAllocations;
